import React from 'react'
import Layout from '../layouts'
import { Link, graphql } from 'gatsby'
import NewsBG from '../images/7686_Dante_Way_Colorado-large-003-11-IGP9369_70_71_72_73sky-1500x994-72dpi.jpg'
import CommonHero from '../components/commonhero/commonhero.js'

class News extends React.Component {
  render() {
    const news = this.props.data.allSanityPost.edges.map(post => post.node)

    return (
      <Layout head={{ title: 'Recent Articles' }}>
        <CommonHero pageTitle="Recent Articles" custombackground={NewsBG} />
        <main className="bg-white">
          <section id="articles" className="component bg-dirty-white">
            <div className="container">
              <div className="row">
                {news.map(post => (
                  <div className="col-md-6 col-lg-4 mb-4" key={post.id}>
                    <div className="card">
                      <div className="card-body articles text-center">
                        <img
                          className="article-banner img-fluid"
                          src={post.mainImage.asset.url}
                          alt="Banner Photos"
                          style={{
                            height: `250px`,
                            width: `100%`,
                            marginBottom: `15px`,
                          }}
                        />
                        <h4 className="card-title font-weight-normal mb-4">
                          <Link to={`news/${post.slug.current}`}>
                            {post.title}
                          </Link>
                        </h4>
                        <p className="card-text px-4">
                          {post.body.replace(/[*#]/g, '').substring(0, 200)}
                          ...
                        </p>
                        <div className="d-flex justify-content-between align-items-center card-footer px-0 bg-white">
                          <div className="card-author px-4">
                            <img
                              className="rounded-circle mr-2"
                              src={post.author.image.asset.url}
                              alt="author"
                              style={{ maxWidth: '45px', height: 'auto' }}
                            />
                            <span>{post.author.name}</span>
                          </div>
                          <div className="card-created px-4">
                            <img
                              alt="clock"
                              src="/img/clock.svg"
                              width="18"
                              className="mr-1 mb-0"
                            />
                            <span className="text-gray">
                              {post.publishedAt || post._createdAt}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </main>
      </Layout>
    )
  }
}

export default News

export const query = graphql`
  query newsQuery {
    allSanityPost(sort: { fields: [_createdAt], order: DESC }) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          shortdesc
          body
          _createdAt(formatString: "MMMM, DD, YYYY")
          publishedAt(formatString: "MMMM, DD, YYYY")
          mainImage {
            asset {
              url
            }
          }
          author {
            name
            image {
              asset {
                url
              }
            }
          }
        }
      }
    }
  }
`
